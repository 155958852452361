import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, Form, Skeleton, Space, Spin } from "antd"
import {
  devLog,
  formatPrice,
  getImageUrl,
  sendCheckoutBeginEvent,
  translatePath,
  uiHandleError,
} from "../../utils"
import { useTranslation } from "react-i18next"
import useDropin from "../../hooks/useDropin"
import ErrorScreen from "../ErrorScreen"
import { navigate } from "gatsby"
import { gql, useQuery } from "@apollo/client"
import { THREED_SECURE_FAILED } from "../../../functions/lib/error-codes"
import CouponSelector from "../customFormItems/CouponSelector"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../constants/imageSizes"

const GET_PURCHASE_QUERY = gql`
  fragment SpecialPrice on special_prices {
    start_at
    end_at
    final_price
  }

  query getPurchase($id: uuid!) {
    user_purchases_by_pk(id: $id) {
      currency
      created_at
      description
      id
      item_id
      price
      special_price
      state
      item_type
      user_id
      VIDEO: video {
        id
        title
        subtitle
        short_description
        duration
        price
        special_price {
          ...SpecialPrice
        }
        price_policy
        landscape {
          s3_key
        }
      }
      COURSE: course {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        price_policy
        landscape {
          s3_key
        }
      }
      CERTIFICATION: certification {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        landscape_success {
          s3_key
        }
        landscape_locked {
          s3_key
        }
        wallpaper {
          s3_key
        }
      }
      GIFT_CARD: gift_card {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        criterion {
          picture {
            s3_key
          }
        }
      }
      BUNDLE: bundle {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        landscape {
          s3_key
        }
      }
    }
  }
`

const CheckoutForm = ({ onFinish, initialValues, purchaseId }) => {
  const { t } = useTranslation()
  devLog({ purchaseId })

  const { loading, error, data } = useQuery(GET_PURCHASE_QUERY, {
    variables: { id: purchaseId },
  })

  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [discount, setDiscount] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [specialPrice, setSpecialPrice] = useState(null)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      return
    }

    let _discount = 0
    let originalPrice = data?.user_purchases_by_pk?.price
    let _finalPrice = data?.user_purchases_by_pk?.special_price || originalPrice

    if (selectedCoupon) {
      const { value, percentage, discount_type } = selectedCoupon.criterion
      if (discount_type === PERCENTAGE) {
        _discount = originalPrice * percentage
      } else {
        _discount = value
      }
      _finalPrice = Math.max(0, _finalPrice - _discount)
    }

    setDiscount(_discount)
    setFinalPrice(_finalPrice)
  }, [selectedCoupon, loading, error, data])

  useEffect(() => {
    if (!data) {
      return
    }
    const { item_type, state, special_price } = data?.user_purchases_by_pk || {}

    if (special_price) {
      setSpecialPrice(special_price)
      setFinalPrice(special_price)
    }
  }, [loading, data])

  const {
    loading: dropinLoading,
    error: dropinError,
    dropinInstance,
    threeDSecureInstance,
    selectedPaymentMethod,
    deviceData,
  } = useDropin("#dropin-container", data?.user_purchases_by_pk?.price)

  const productPreview = useMemo(() => {
    if (loading) {
      return <Skeleton active round />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    if (!data?.user_purchases_by_pk) {
      return <ErrorScreen error={new Error(t("error:itemNotFound"))} />
    }

    if (data?.user_purchases_by_pk.state === "COMPLETED") {
      navigate(translatePath("/checkout/success") + `?id=${purchaseId}`, {
        replace: true,
      })
      return null
    }

    sendCheckoutBeginEvent()

    const { item_type, state } = data?.user_purchases_by_pk || {}
    const item = data?.user_purchases_by_pk[item_type]
    const {
      title,
      subtitle,
      short_description,
      price,
      landscape,
      landscape_locked,
      landscape_success,
      criterion,
    } = item || {}
    const coverUrl = getImageUrl(
      landscape_locked || landscape_success || landscape || criterion?.picture,
      DEFAULT_LANDSCAPE_IMAGE_SIZE
    )

    return (
      <div className="purchase-preview-wrapper">
        <Card
          style={{ width: "100%", marginBottom: 50 }}
          hoverable
          cover={<img alt={title} src={coverUrl} />}
        >
          <Card.Meta title={title} description={short_description} />
        </Card>
      </div>
    )
  }, [loading, error, data])

  const priceLabel = useMemo(() => {
    if (loading) {
      return <Spin spinning />
    }

    const price = data?.user_purchases_by_pk?.price

    if (price !== finalPrice) {
      return (
        <span>
          <span className="stroked-price">{formatPrice(price)}</span>
          <span className="final-price">{formatPrice(finalPrice)}</span>
        </span>
      )
    }

    return <span>{formatPrice(price)}</span>
  }, [loading, error, data, finalPrice])

  const handleCheckout = async values => {
    devLog("Checking out")
    if (finalPrice <= 0) {
      onFinish(
        {
          nonce: "",
          deviceData: "",
          couponCode: values.coupon_code,
        },
        purchaseId
      )

      return
    }

    dropinInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: finalPrice,
          challengeRequested: true,
        },
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (payload.type === "CreditCard") {
          if (!payload.liabilityShifted && !payload.liabilityShiftPossible) {
            return uiHandleError({
              error: new Error(THREED_SECURE_FAILED),
            })
          } else {
            onFinish(
              {
                nonce: payload.nonce,
                deviceData: payload.deviceData,
                couponCode: values.coupon_code,
              },
              purchaseId
            )
          }
        } else {
          onFinish(
            {
              nonce: payload.nonce,
              deviceData: payload.deviceData,
              couponCode: values.coupon_code,
            },
            purchaseId
          )
        }
      }
    )
  }

  return (
    <Form
      onFinish={handleCheckout}
      layout={"vertical"}
      initialValues={initialValues}
    >
      {productPreview}
      {/*{couponFormItems}*/}
      <Form.Item label={t("form:label.couponCode")} name="coupon_code">
        <CouponSelector
          placeholder={t("form:label.couponCode")}
          size="large"
          itemId={data?.user_purchases_by_pk?.item_id}
          itemType={data?.user_purchases_by_pk?.item_type}
          onCouponSelect={coupon => {
            devLog({ coupon })
            setSelectedCoupon(coupon)
          }}
        />
      </Form.Item>
      <Spin spinning={loading}>
        <div id="dropin-container" />
        {dropinError && <ErrorScreen error={dropinError} />}
      </Spin>
      <br />
      <br />
      <Space>
        <Button
          className="pay-button purple"
          type="primary"
          disabled={!dropinInstance && finalPrice !== 0}
          htmlType="submit"
        >
          <span>
            {t("message:completeYourPurchase")} - {priceLabel}
          </span>
        </Button>
      </Space>
    </Form>
  )
}

export default CheckoutForm
